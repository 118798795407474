import React, { useEffect } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { connect } from "react-redux"
import * as actions from "@redux/actions"
import Styled from "styled-components"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { Button } from "@components/Form"

const BtnContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const ProfilePage = ({ authenticated, ReduxLogout, ClearErrorSuccess }) => {
  const data = useStaticQuery(graphql`
    query ProfileQuery {
      banner: allFile(filter: { name: { eq: "client_portal_1440x400" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!authenticated) {
      ReduxLogout()
    }
    ClearErrorSuccess()
  }, [authenticated])

  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Client Profile" />
        <div className="row mt-5">
          <BtnContainer className="col-md-10 mx-auto text-center">
            <Button onClick={() => navigate("/app/profile/create-checkin")}>
              Create Checkin
            </Button>
            <Button onClick={() => navigate("/app/profile/previous-checkins")}>
              View Past Checkins
            </Button>
          </BtnContainer>
          <BtnContainer className="col-md-10 mx-auto text-center">
            <Button onClick={() => navigate("/app/profile/payment-settings")}>
              Update Payment Settings
            </Button>
            <Button onClick={() => ReduxLogout()}>Logout</Button>
          </BtnContainer>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
  }
}
export default connect(mapStateToProps, actions)(ProfilePage)
